<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Facility Info
        </h4>
        <div v-if="!loadingData && listExportHub.data.length < 1">
          <alert-status
            status="warning"
            label="Must add Export Hub first if you want to add this facility."
          />
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  VC Code
                </label>
                <div class="uk-form-controls uk-flex">
                  <div class="uk-width-1-2 uk-margin-right">
                    <input
                      :value="export_hub.evc_code"
                      name="vc_code"
                      class="uk-input"
                      type="text"
                      placeholder="EVC Code"
                      disabled
                    >
                  </div>
                  <div class="uk-width-1-2">
                    <input
                      v-model="formHulling.vc_code"
                      v-validate="'required|max:50'"
                      name="vc_code"
                      class="uk-input"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter VC Code"
                      :disabled="export_hub == ''"
                      :class="{'uk-form-danger': errors.has('vc_code')}"
                    >
                    <span
                      v-show="errors.has('vc_code')"
                      class="uk-text-small uk-text-danger"
                    >{{ errors.first('vc_code') }}</span>
                  </div>
                </div>
              </div>
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Export Hub
                </label>
                <div class="uk-form-controls">
                  <multiselect 
                    v-model="export_hub" 
                    v-validate="'required'"
                    label="name" 
                    name="export_hub" 
                    track-by="id" 
                    placeholder="Select Export Hub or Type to Search" 
                    open-direction="bottom" 
                    :options="listExportHub.data"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    @search-change="(input) => handleSearchDropdown(listExportHub.data, input)"
                    @select="() => recallApiList()"
                    @remove="() => recallApiList()"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('export_hub')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('export_hub') }}</span>
              </div>
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Latitude
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formHulling.latitude"
                    v-validate="'required|max:100'"
                    name="latitude"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Latitude"
                    :class="{'uk-form-danger': errors.has('latitude')}"
                  >
                </div>
                <span
                  v-show="errors.has('latitude')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('latitude') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formHulling.facility_name"
                    v-validate="'required|max:50'"
                    name="facility_name"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Facility Name"
                    :class="{'uk-form-danger': errors.has('facility_name')}"
                  >
                </div>
                <span
                  v-show="errors.has('facility_name')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('facility_name') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Address
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formHulling.address"
                    v-validate="'required|max:100'"
                    name="address"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Address"
                    :class="{'uk-form-danger': errors.has('address')}"
                  >
                </div>
                <span
                  v-show="errors.has('address')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('address') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Longitude
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formHulling.longitude"
                    v-validate="'required|max:100'"
                    name="longitude"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Longitude"
                    :class="{'uk-form-danger': errors.has('longitude')}"
                  >
                </div>
                <span
                  v-show="errors.has('longitude')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('longitude') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Lapang Jemur
      </h4>
      <div class="uk-form-stacked form-dry-fields">
        <div
          v-for="(data, index) in drying_field_list"
          :key="index"
          class="uk-child-width-expand@s uk-grid-small" 
          uk-grid
        >
          <div class="uk-width-1-2">
            <div class="uk-form-controls uk-position-relative withlabel">
              <label class="uk-form-label">
                Type {{ index + 1 }}
              </label>
              <select
                v-model="data.drying_field_type_id"
                class="uk-width-expand custom-select"
                name="drying_field_type_id"
              >
                <option
                  value=""
                  hidden
                >
                  Select Type
                </option>
                <option
                  v-for="(item, key) in listDryingField"
                  :key="key"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <img
                :src="`${images}/icon/ic_chevron_down.svg`"
                alt="ic_chevron"
              >
            </div>
            <span
              v-show="errors.has(`drying_field_type_${index + 1}`)"
              class="uk-text-small uk-text-danger"
            >{{ errors.first(`drying_field_type_${index + 1}`) }}</span>
          </div>

          <div class="uk-width-1-2">
            <label class="uk-form-label">
              Name {{ index + 1 }}
            </label>
            <div class="uk-form-controls">
              <input
                v-model="data.drying_field_name"
                :name="`drying_field_name_${index + 1}`"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Lapang Jemur Name"
                :class="{'uk-form-danger': errors.has(`drying_field_name_${index + 1}`)}"
              >
              <span
                v-show="errors.has(`drying_field_name_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`drying_field_name_${index + 1}`) }}</span>
            </div>
          </div>

          <div
            class="img-fields"
            :hidden="index > 0 ? false : true"
          >
            <img
              :src="`${images}/icon/ic_delete_table.svg`"
              alt=""
              @click.prevent="handleDeleteFormDrying()"
            >
          </div>
        </div>
      </div>
      
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
        uk-grid
      >
        <button
          class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
          style="justify-content: flex-start;"
          @click.prevent="handleCreateFormDrying()"
        >
          <img
            :src="`${images}/icon/ic_plus_green.svg`"
            class="uk-margin-small-right cursor-pointer"
          >
          Add Lapang Jemur
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        App Access
      </h4>
      <div class="uk-margin-top uk-child-width-expand@s">
        <div class="uk-overflow-auto border-table">
          <table
            class="uk-table uk-table-divider uk-table-middle"
            aria-describedby="hulling-facility-table"
          >
            <thead class="thead-table-varion">
              <tr>
                <th class="uk-table-expand">
                  Menu Name
                </th>
                <th class="uk-width-small" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Terima Gabah</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formHulling.recipient"
                      @change="handleChangeRecipient"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pre-Hull Drying</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formHulling.pre_hull_dryer"
                      @change="handleChangePreHulling"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Hulling</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formHulling.huller"
                      @change="handleChangeHuller"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pengeringan Labu</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formHulling.pumpkin_dryer"
                      @change="handleChangePumpkinDryer"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pengepakan</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formHulling.courier"
                      @change="handleChangeCourier"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-flex uk-flex-right uk-margin-medium-top">
        <button
          class="uk-button soft-green uk-margin-right"
          @click="showCancelConfirmModal"
        >
          Cancel
        </button>
        <button
          class="uk-button green"
          :class="isCanAccessUser('add', 'HULLING FACILITY') ? '' : 'disabled'"
          :disabled="!isCanAccessUser('add', 'HULLING FACILITY')"
          @click="showSaveConfirmModal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AlertStatus from '@/components/globals/AlertStatus'
import { isCanAccess } from '@/utils/auth'
import { mapActions } from 'vuex'

export default {
  components: {
    AlertStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    loadingData:{
      type: Boolean
    },
    listDryingField: {
      required: true,
      type: Array
    },
    listExportHub: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      export_hub: '',
      isHideCreateButton: false,
      drying_field_list: [
        { 
          drying_field_type_id: '',
          drying_field_name: ''
        }
      ],
      onSearch: false,
      formHulling: {
        vc_code: ``,
        facility_name: '',
        latitude: '',
        longitude: '',
        export_hub_id: '',
        address: '',
        drying_field_list: [],
        recipient: true,
        pre_hull_dryer: true,
        huller: true,
        pumpkin_dryer: true,
        courier: true
      }
    }
  },
  watch: {
    export_hub(val) {
      if(val) {
        this.formHulling.vc_code = 'H'
      }
    }
  },
  methods: {
    ...mapActions({
      actionGetListExportHub: 'exportHub/getListExportHub'
    }),
    async handleSearchDropdown(data, input) {
      const dataList = data
  
      const checker = dataList.some((item) => {
        const lowerFacilityName = item.facility_name ? item.facility_name.toLowerCase() : item.name.toLowerCase()
        const lowerSearchQuery = input.toLowerCase()
        return lowerFacilityName.includes(lowerSearchQuery)
      })

      if (!checker && !(dataList.length < 1)) {
        this.onSearch = true
        await this.actionGetListExportHub({ search: input })
      }

      if (!input) {
        let i = input
        if(!(i.includes(input)) || dataList.length < 1) {
          await this.actionGetListExportHub({ page: 1, limit: 50 })
         
        }
      }
    },
    async recallApiList(){
      if(this.onSearch){
        this.onSearch = false
        await this.actionGetListExportHub({ page: 1, limit: 50 })
      }
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    handleCreateFormDrying() {
      this.drying_field_list.push({
        drying_field_type_id: '',
        drying_field_name: ''
      })
    },
    handleDeleteFormDrying() {
      this.drying_field_list.splice(this.drying_field_list.length - 1, 1)
    },
    handleChangeRecipient(event) {
      this.formHulling.recipient = event.target.checked ? true : false
    },
    handleChangePreHulling(event) {
      this.formHulling.pre_hull_dryer = event.target.checked ? true : false
    },
    handleChangeHuller(event) {
      this.formHulling.huller = event.target.checked ? true : false
    },
    handleChangePumpkinDryer(event) {
      this.formHulling.pumpkin_dryer = event.target.checked ? true : false
    },
    handleChangeCourier(event) {
      this.formHulling.courier = event.target.checked ? true : false
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'HullingFacility',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.formHulling.export_hub_id = this.export_hub ? this.export_hub?.id : ''
      let tempDrying = []
      this.drying_field_list.map(item => tempDrying.push({
        drying_field_type_id: item.drying_field_type_id,
        drying_field_name: item.drying_field_name
      }))
      this.formHulling.drying_field_list = tempDrying 
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formHulling)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .form-dry-fields .uk-width-1-2 {
    max-width: 48%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
  }
  .uk-table thead tr {
    border: 1px solid #E0E0E0;
  }
  .uk-table tbody tr {
    border: 1px solid #E0E0E0;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
</style>
